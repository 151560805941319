import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const RegionalUrologyLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Moss"
    institution="Regional Urology"
    referralCode="REGIONALUROLOGY"
    physicianURL="http://www.regionalurology.com/"
  />
)

export default RegionalUrologyLandingPage
